import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { BlogTable } from "../../../../components/Blog/postElements/BlogTable";
import { PostIntro } from "../../../../components/Blog/postElements/PostIntro";
import { FullWidthImage } from "../../../../components/Blog/postElements/FullWidthImage";
import { HtmlElement } from "../../../../components/Blog/postElements/HtmlElement";
import { BannerMobileApp } from "../../../../components/Blog/postElements/BannerMobile";
import { Quote } from "../../../../components/Blog/postElements/Quote";
export const _frontmatter = {
  "title": "WebRTC SDKs For Mobile Apps - Agora vs Quickblox vs Twilio",
  "authors": ["Marek Gocał"],
  "abstract": "Agora vs Quickblox vs Twilio. Which webRTC SDK provider for mobile applications is the best. Best solutions for Flutter or native mobile application. Read an article that compares popular options on the market in terms of price, performance and security",
  "imageSource": "./main.png",
  "link": "webrtc-mobile-sdk/Agora-vs-Quickblox-vs-Twilio",
  "date": "2022-02-28T06:32:51.343Z",
  "seoTitle": "Best flutter and native mobile SDK for mobile WebRTC",
  "seoDescription": "Agora vs Quickblox vs Twilio. Which webRTC SDK provider for mobile applications is the best. Best solutions for Flutter or native mobile application. Read an article that compares popular options on the market in terms of price, performance and security",
  "seoKeyPhrase": "top flutter and native mobile WebRTC SDK",
  "seoKeyWords": "Agora, Quickblox, Twilio, Agora vs Quickblox vs Twilio, WebRTC, WebRTC SDK, WebRTC mobile, WebRTC integration, P2P, Video calls, teleconferencing, tele conference, tele-medicine apps, how to build audio video app, how to build telemedicine, how to integrate video chat",
  "categories": ["Technology", "WebRTC", "Video-Chat", "Telemedicine"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <PostIntro title="Introduction" description={<p>WebRTC has been on the market for ten years and is a widely accepted and recognized standard. It is a commonly used solution in healthcare, financial services, and well-known products like Google Hangouts and Facebook. Nowadays, if you plan to build any</p>} imageUrl={"/img/webRtcSdks/main.png"} mdxType="PostIntro" />
    <p>{`product focusing on audio-video chat or file transfer, you should consider WebRTC.`}</p>
    <p>{`WebRTC is an open-source video technology that allows users to connect directly by bypassing an external proxy server in the connection. Thanks to this, such a connection can be called real-time. As a result, delays and data losses are minimized, ensuring the highest quality connection.`}</p>
    <p>{`In this article, we will focus on WebRTC SDK providers supporting mobile platforms.`}</p>
    <h2 {...{
      "id": "Why-mobile-SDK-for-WebRTC"
    }}>{`Why mobile SDK for WebRTC?`}</h2>
    <p>{`WebRTC was introduced in 2011 and since then it has been continuously developed to support most platforms and technologies. According to the official sources, WebRTC is already supported in most browsers and operating systems, but our experience shows us there are some limitations.`}</p>
    <p>{`WebRTC for mobile browsers is unsupported on iOS, while Android only partially supports it. Fortunately Android and iOS offer decent support for WebRTC when implemented within a mobile application.`}</p>
    <p>{`A number of companies have built SDKs to make WebRTC integration more straightforward, easy and reliable. This helps development teams save valuable time and focus on the other core functionalities of the product.`}</p>
    <p>{`I am Marek Gocał from AllBright.io, and in today`}{`’`}{`s article, I will cover some of the most popular solution providers available on the market. We will focus on factors such as costs, the services available, and technological limitations.`}</p>
    <FullWidthImage imageSrc="/img/webRtcSdks/whywebrtc.png" imageAlt='why webrtc' mdxType="FullWidthImage" />
    <h2 {...{
      "id": "WebRTC-mobile-vendors"
    }}>{`WebRTC mobile vendors`}</h2>
    <p>{`First, let`}{`’`}{`s introduce the WebRTC providers that we will compare in this article. Each provides WebRTC services for mobile and web.`}</p>
    <HtmlElement element={<div className='custom-h3'><h3>Agora</h3><img src='/img/webRtcSdks/agoralogo.png' style={{
        transform: 'translateY(10px)'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`Agora was established in the year 2013. It focussed on WebRTC technology from the start, understanding its potential. It is currently the market leader in this technology for mobile platforms and more, unequaled for efficient audio transfer. They have offices in Santa Clara, California, and Shanghai, China. Companies like TalkSpace, Bunch, and Meet Me are among its clients.`}</p>
    <HtmlElement element={<div className='custom-h3'><h3>Twilio</h3><img src='/img/webRtcSdks/twiliologo.png' style={{
        transform: 'translateY(15px) translateX(-15px)',
        height: '50px'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`Twilio is a company that began in 2008 with a focus on phone calls and text messaging. Since then, it has set the gold standard for cloud communication. For example, through a partnership with Twilio, GroupMe received $10.6 million in capital venture funds in 2010. Twilio now has 26 offices in 16 countries and supplies WebRTC technology to firms like ING, MorganStanley, Dell, and, since early 2021, CipherHealth too.`}</p>
    <HtmlElement element={<div className='custom-h3'><h3>Quickblox</h3><img src='/img/webRtcSdks/quicklogo.png' style={{
        transform: 'translateY(7px)',
        height: '33px'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`Quickblox was established in 2009. The company provides a cloud communication backend service that includes chat, video calling, and push notification functionalities for mobile, web, and desktop apps. It has branches in countries such as the USA, UK, Ukraine, and India. We can see social networking apps and dozens of enterprises among their clients, including Unilever, BlackBerry, and Barclays.`}</p>
    <h2 {...{
      "id": "What-is-the-difference-between-Agora-Twilio-and-Quickblox"
    }}>{`What is the difference between Agora, Twilio and Quickblox?`}</h2>
    <p>{`Most WebRTC providers try to make the implementation of their technology easier, faster and cheaper each year. Work on improving the first two continues as peer-to-peer technology develops. However, pricing is already an element that makes it simple to get started with WebRTC. For example, most providers offer a free version of their service so developers can test a product and release an MVP without exposing themselves to any cost. In this case, Quickblox and Agora are examples of such a system. On the other hand, Twilio does not offer this approach, and developers must pay from the start.`}</p>
    <p>{`Each provider focuses on a different aspect of the technology. For example, some are trying to perfect the main features of WebRTC, video and voice communication. In contrast, others emphasize different functions.`}</p>
    <h2 {...{
      "id": "Comparison-criterias"
    }}>{`Comparison criterias`}</h2>
    <p>{`In the following sections, we`}{`’`}{`ll use several categories to assess the three WebRTC providers:`}</p>
    <ul>
      <li parentName="ul">{`Features`}</li>
      <li parentName="ul">{`Pricing`}</li>
      <li parentName="ul">{`Platform technological limitations`}</li>
      <li parentName="ul">{`Security`}</li>
    </ul>
    <p>{`In the penultimate section, we will discuss why it makes economic sense to use the services of a WebRTC provider instead of building an implementation yourself. Finally, the last section presents a summary of the advantages and disadvantages of each provider`}{`’`}{`s offering.`}</p>
    <h3 {...{
      "id": "Features"
    }}>{`Features`}</h3>
    <p>{`Even though webRTC is primarily associated with video chat, companies providing this service have begun to offer many other valuable functionalities. Most companies divide their functionalities into three categories, namely video, audio and messaging. Now let`}{`’`}{`s look at the features found within these categories.`}</p>
    <h2 {...{
      "id": "Video-with-WebRTC"
    }}>{`Video with WebRTC`}</h2>
    <HtmlElement element={<div className='custom-h3'><h3>Agora</h3><img src='/img/webRtcSdks/agoralogo.png' style={{
        transform: 'translateY(10px)'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`In the case of Agora, we have four types of services: video and streaming, in both Basic and HD+ variants. HD+ has increased resolution, and the Basic is below 720p. The prices for them are as follows:`}</p>
    <FullWidthImage imageSrc="/img/webRtcSdks/pricing.png" imageAlt='why webrtc' mdxType="FullWidthImage" />
    <p>{`What may not be obvious is that the resolutions of the transmitted data refer to the video resolutions of all participants combined. Therefore, if at least one participant uses the HD resolution, any additional user will result in a price increase. The limit for the Basic option relates to the aggregate resolution, which cannot exceed 921,600.`}</p>
    <FullWidthImage imageSrc="/img/webRtcSdks/pricing2.png" imageAlt='why webrtc' mdxType="FullWidthImage" />
    <p>{`Apart from video chat, Agora also offers streaming, a feature the competition lacks. Agora’s streaming supports up to one million viewers in a single session. It is also worth mentioning that Agora uses the same audio module in the video chat as audio-only communication. Why is this an advantage? Find out in the next section, “Audio only” `}</p>
    <HtmlElement element={<div className='custom-h3'><h3>Twilio</h3><img src='/img/webRtcSdks/twiliologo.png' style={{
        transform: 'translateY(15px) translateX(-15px)',
        height: '50px'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`Twilio offers two different price options, one-to-one chat, and group video chat. For the one-to-one option, the price is $ 0.0015 per minute for each participant. Group chats can accommodate up to 50 participants, and the price is $ 0.004 per minute for each connected person.`}</p>
    <p>{`An additional feature is the unlimited TURN relay. As you may already know, WebRTC uses a stun (Session Traversal Utilities for NAT) server to establish a direct connection between two devices, i.e., P2P. The connection established in this way comes with all the benefits of WebRTC, including very low latency and almost lossless transmission. Unfortunately, sometimes it is not possible to establish such a connection.`}</p>
    <p>{`We won`}{`’`}{`t dwell on why this happens. However, if you are interested, you can read more about it in our article `}{`”`}{`WebRTC: How Does It Work?`}{`”`}{` (link to article and section How does it work). When a P2P connection fails, another way to connect clients is needed, which is where the TURN server steps into the picture and becomes an intermediary.`}</p>
    <p>{`As our app scales, the TURN server may become very expensive, so unlimited access to it is a huge advantage.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/webRtcSdks/quickblox.png",
        "alt": null
      }}></img></p>
    <HtmlElement element={<div className='custom-h3'><h3>Quickblox</h3><img src='/img/webRtcSdks/quicklogo.png' style={{
        transform: 'translateY(7px)',
        height: '33px'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`QuickBlox offers solution bundles, which means that by selecting one of the bundles, we either get some or all of the features available.`}</p>
    <p>{`Regardless of the bundle`}{`’`}{`s price ( `}<strong parentName="p">{`paid monthly, not by the minute`}</strong>{` ), Quickblox allows you to choose the perfect resolution. Of course, this depends on the connection quality, with the lowest being 320p, up to the highest at 1980p. However, a P2P connection via a STUN server with more than two participants is unwise as most internet connections will not handle this amount of data. We recommend setting the resolution at 720p or lower for the same reason.`}</p>
    <p>{`Thanks to this, we do not have to pay separately for the video chat service, screen sharing etc. All these functions are available at no extra cost.`}</p>
    <p>{`Additionally, Quickblox specializes in three areas: healthcare, finance and marketplace.`}</p>
    <HtmlElement element={<div className='custom-h3'><h3>Summary</h3></div>} mdxType="HtmlElement" />
    <p>{`Every provider wants to deliver WebRTC’s video in the highest quality, so it’s no wonder that all three companies offer very similar quality and functions. However, if we look closer, we see two that stand out. The first is Twilio due to ease of scaling, the video chat option with up to 50 participants, and it’s affordable prices. The second is Agora, which wins with great prices at the start and the streaming feature. Finally, as previously mentioned, Quickblox is a good choice if you are interested in a simple solution that provides video, audio, and messaging in one package.`}</p>
    <h3 {...{
      "id": "Audio-with-WebRTC"
    }}>{`Audio with WebRTC`}</h3>
    <p>{`One of the main advantages of using WebRTC technology is the near-lossless video transmission. High-quality voice transmission is, however, equally important. Sometimes sound transmission alone is needed, so it`}{`’`}{`s worth taking a closer look at this function, especially as it is likely to reduce costs.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/webRtcSdks/agoraio.png",
        "alt": null
      }}></img></p>
    <HtmlElement element={<div className='custom-h3'><h3>Agora</h3><img src='/img/webRtcSdks/agoralogo.png' style={{
        transform: 'translateY(10px)'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`Agora provides functions dedicated to the gaming industry, which is the most demanding for audio-only communication.`}</p>
    <p>{`Gamers require perfect sound synchronization and no delay. A byproduct is that other industries, such as tech support, finance, and telemedicine, get to enjoy this premium audio performance too.`}</p>
    <p>{`Agora also provides audio features, including surround sound, stereo at up to 48KHz, and active speaker detection. These functions ensure Agora audio quality is top-notch and comfortable to use. The price per minute is `}<strong parentName="p">{`$ 0.00099`}</strong>{` for each participant in the call.`}</p>
    <HtmlElement element={<div className='custom-h3'><h3>Twilio</h3><img src='/img/webRtcSdks/twiliologo.png' style={{
        transform: 'translateY(15px) translateX(-15px)',
        height: '50px'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`The price of voice calls for the web is the same for outgoing and incoming calls and it is $ 0.0040 / min. The price for phone calls is different for outgoing calls $ 0.013 / min and incoming calls $ 0.0085 / min. This service is aimed primarily at the US market, but the company ensures it can provide the same quality in other parts of the world.`}</p>
    <p>{`Unfortunately, the company does not provide clear information on the quality of the connection or the codecs used. We tried to contact the company to ask additional questions on this matter. The company repeatedly ignored our questions and attempts to establish a conversation. When the interview finally took place, it turned out that the consultants were unable to provide such information. For this reason, we cannot guarantee that the quality of the connection offered by Twilio is satisfactory. In addition, the quality of the support offered by the company is very low, suggesting that communication problems may arise in the future.`}</p>
    <HtmlElement element={<div className='custom-h3'><h3>Quickblox</h3><img src='/img/webRtcSdks/quicklogo.png' style={{
        transform: 'translateY(7px)',
        height: '33px'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`QuickBlox offers solution bundles, which means that by selecting one of the bundles, we either get some or all of the features available.`}</p>
    <p>{`Regardless of the bundle’s price (paid monthly, not by the minute), Quickblox allows you to choose the perfect resolution. Of course, this depends on the connection quality, with the lowest being 320p, up to the highest at 1980p. However, a P2P connection via a STUN server with more than two participants is unwise as most internet connections will not handle this amount of data. We recommend setting the resolution at 720p or lower for the same reason.`}</p>
    <p>{`Thanks to this, we do not have to pay separately for the video chat service, screen sharing etc. All these functions are available at no extra cost.`}</p>
    <p>{`Additionally, Quickblox specializes in three areas: healthcare, finance and marketplace. `}</p>
    <HtmlElement element={<div className='custom-h3'><h3>Summary of Audio only with WebRTC</h3></div>} mdxType="HtmlElement" />
    <p>{`The audio quality is undoubtedly one of Agora`}{`’`}{`s main advantages over the competition. Their offer is not only characterized by premium quality but also provides several additional interesting functions. An amazing advantage is also the fact that when one of the users cannot send or receive video transmission, the only sound is sent and the cost of such transmission is calculated according to the audio package. When we are interested in the highest quality audio connection Agora is the best solution.`}</p>
    <h3 {...{
      "id": "Instant-Messaging-with-WebRTC"
    }}>{`Instant Messaging with WebRTC`}</h3>
    <p>{`Life messaging is a functionality that is very often supported by mobile applications, especially those that offer communication via video chat. WebRTC providers have noticed this need in their clients, so it has become a standard that these companies also offer real-time messaging.`}</p>
    <HtmlElement element={<div className='custom-h3'><h3>Agora</h3><img src='/img/webRtcSdks/agoralogo.png' style={{
        transform: 'translateY(10px)'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`Until recently, live messaging was not supported by this company. Recently, however, work has been underway on this functionality and as of today, it is in the beta phase. The fact that the feature is not yet released in its full version, however, is not a big obstacle. The function is currently working very well and has been thoroughly tested by us in our video application for the deaf developed with flutter.`}</p>
    <p>{`If you want to learn more about the challenges and advantages of using Agora, please visit our article devoted to the case study of the above-mentioned application called `}<a parentName="p" {...{
        "href": "http://www.captio.cc/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Captio`}</a>{`.`}</p>
    <HtmlElement element={<div className='custom-h3'><h3>Twilio</h3><img src='/img/webRtcSdks/twiliologo.png' style={{
        transform: 'translateY(15px) translateX(-15px)',
        height: '50px'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`As you could see when discussing audio functionality, Twilio does not stop at web communication, but also tries to provide its services in a wider range. That is why the company allows for integration with various types of messaging in its service. Programmable SMS, WhatsApp, MMS. Their range of services in this category is gigantic, so if you are interested in extensive chat functionality, Twilio is the best solution.`}</p>
    <HtmlElement element={<div className='custom-h3'><h3>Quickblox</h3><img src='/img/webRtcSdks/quicklogo.png' style={{
        transform: 'translateY(7px)',
        height: '33px'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`As with audio, the company doesn`}{`’`}{`t share the exact specification of their messaging service. Everything in one package is tailored to your requirements. This is the company`}{`’`}{`s policy.`}</p>
    <h3 {...{
      "id": "Summary"
    }}>{`Summary`}</h3>
    <p>{`The messaging functionality is not the main feature we expect from a WebRTC provider, but it is certainly helpful and most of the time it is required in our mobile application. In this case, each company has some solutions to offer, but Twilio presents the broadest offer in the messaging category. Once again, Quickblox is a good solution, and Agora works very well today, but it`}{`’`}{`s worth to wait until it leaves beta.`}</p>
    <h2 {...{
      "id": "Pricing"
    }}>{`Pricing`}</h2>
    <h3 {...{
      "id": "WebRTC-mobile-application-costs"
    }}>{`WebRTC mobile application costs`}</h3>
    <p>{`WebRTC providers strive to make it easier to get started with their product. This is very visible in the pay-as-you-go philosophy, which is generally accepted by most companies, i.e. zero costs to start. Some of them use the subscription approach, but even then they often have a free offer available. This approach means that you can start working on the application and test it without incurring unnecessary costs. The next question is how much does each service cost after exceeding the free thresholds?`}</p>
    <p>{`Comparing costs is much more difficult than it might seem at first glance. It is not enough to compare the tables in the pricing section for each solution, because there are too many variables here. Besides, there are many problems and aspects that may turn out to be unclear at first glance.`}</p>
    <p>{`That is why I have prepared a special spreadsheet for you that will allow you to adjust the appropriate parameters according to your business needs. Below in the text, there is also a list of information that should be read, so that you won`}{`’`}{`t be surprised by some unexpected costs. All pieces of information contained in the article are current as of (date of publication of the article) and to avoid mistakes, it is recommended to check whether the offer of any of the discussed suppliers has not changed.`}</p>
    <p>{`Basic - 3.99$/1000 minutes`}</p>
    <p>{`Full HD - 8.99/1000 minutes`}</p>
    <HtmlElement element={<div className='blog-table-container'>
  <div className='blog-table'>
    <div className='blog-table-cell'>
      <table cellSpacing="0" cellPadding="0">
  <tbody>
    <tr style={{
                "height": "20px"
              }}>
      <td className="s0" rowSpan="2"></td>
      <td className="s0"></td>
      <td className="s0"></td>
      <td className="s1 black" dir="ltr" colSpan="2">
        Price per minute
      </td>
      <td className="s0"></td>
      <td className="s0"></td>
    </tr>
    <tr style={{
                "height": "20px"
              }}>
      <td className="s3" dir="ltr">
        Type
      </td>
      <td className="s4 softmerge" dir="ltr">
        <div className="softmerge-inner black" style={{
                    "width": "149px",
                    "left": "-3px"
                  }}>
          Time in minutes
        </div>
      </td>
      <td className="s3 black" dir="ltr">
        Make call
      </td>
      <td className="s5 softmerge" dir="ltr">
        <div className="softmerge-inner black" style={{
                    "width": "103px",
                    "left": "-3px"
                  }}>
          Recive call
        </div>
      </td>
      <td className="s4 softmerge" dir="ltr">
        <div className="softmerge-inner black" style={{
                    "width": "123px",
                    "left": "-3px"
                  }}>
          Free minutes
        </div>
      </td>
      <td className="s1 black" dir="ltr">
        Price $
      </td>
    </tr>
    <tr style={{
                "height": "20px"
              }}>
      <td className="s1" dir="ltr">
        QuickBlox
      </td>
      <td className="s6 black" dir="ltr">
        Audio/Video
      </td>
      <td className="s6">300000</td>
      <td className="s6" dir="ltr">
        0
      </td>
      <td className="s6" dir="ltr">
        0
      </td>
      <td className="s6" dir="ltr">
        0
      </td>
      <td className="s6" dir="ltr">
        99
      </td>
    </tr>
    <tr style={{
                "height": "20px"
              }}>
      <td className="s7" dir="ltr" rowSpan="8">
        Agora
      </td>
      <td className="s6 black" dir="ltr">
        Audio
      </td>
      <td className="s6">3000000</td>
      <td className="s6" dir="ltr">
        0,00099
      </td>
      <td className="s6" dir="ltr">
        0,00099
      </td>
      <td className="s6" dir="ltr">
        10&nbsp;000
      </td>
      <td className="s6">0</td>
    </tr>
    <tr style={{
                "height": "20px"
              }}>
      <td className="s6" dir="ltr">
        Audio Streaming
      </td>
      <td className="s6">3000000</td>
      <td className="s6" dir="ltr">
        0,00099
      </td>
      <td className="s6" dir="ltr">
        0,00099
      </td>
      <td className="s6" dir="ltr">
        10&nbsp;000
      </td>
      <td className="s6">0</td>
    </tr>
    <tr style={{
                "height": "20px"
              }}>
      <td className="s6" dir="ltr">
        Video
      </td>
      <td className="s6">3000000</td>
      <td className="s6" dir="ltr">
        0,00399
      </td>
      <td className="s6" dir="ltr">
        0,00399
      </td>
      <td className="s6" dir="ltr">
        10&nbsp;000
      </td>
      <td className="s6">13&nbsp;940</td>
    </tr>
    <tr style={{
                "height": "20px"
              }}>
      <td className="s6" dir="ltr">
        video HD+
      </td>
      <td className="s6">3000000</td>
      <td className="s6" dir="ltr">
        0,00899
      </td>
      <td className="s6" dir="ltr">
        0,00899
      </td>
      <td className="s6" dir="ltr">
        10&nbsp;000
      </td>
      <td className="s6">43&nbsp;940</td>
    </tr>
    <tr style={{
                "height": "20px"
              }}>
      <td className="s6" dir="ltr">
        Video Streaming Premium HD
      </td>
      <td className="s6">6000000</td>
      <td className="s6" dir="ltr">
        0,00399
      </td>
      <td className="s6" dir="ltr">
        0,00399
      </td>
      <td className="s6" dir="ltr">
        10&nbsp;000
      </td>
      <td className="s6">13&nbsp;940</td>
    </tr>
    <tr style={{
                "height": "20px"
              }}>
      <td className="s8 softmerge" dir="ltr">
        <div className="softmerge-inner" style={{
                    "width": "285px",
                    "left": "-11px"
                  }}>
          Video Streaming Premium HD+
        </div>
      </td>
      <td className="s6">6000000</td>
      <td className="s6" dir="ltr">
        0,00899
      </td>
      <td className="s6" dir="ltr">
        0,00899
      </td>
      <td className="s6" dir="ltr">
        10&nbsp;000
      </td>
      <td className="s6">43&nbsp;941</td>
    </tr>
    <tr style={{
                "height": "20px"
              }}>
      <td className="s6" dir="ltr">
        Video Streaming Standard HD
      </td>
      <td className="s6">6000000</td>
      <td className="s6" dir="ltr">
        0,00399
      </td>
      <td className="s6" dir="ltr">
        0,00199
      </td>
      <td className="s6" dir="ltr">
        10&nbsp;000
      </td>
      <td className="s6">1&nbsp;940</td>
    </tr>
    <tr style={{
                "height": "20px"
              }}>
      <td className="s8 softmerge" dir="ltr">
        <div className="softmerge-inner" style={{
                    "width": "286px",
                    "left": "-12px"
                  }}>
          Video Streaming Standard HD+
        </div>
      </td>
      <td className="s6">6000000</td>
      <td className="s6" dir="ltr">
        0,00899
      </td>
      <td className="s6" dir="ltr">
        0,00459
      </td>
      <td className="s6" dir="ltr">
        10&nbsp;000
      </td>
      <td className="s6">17&nbsp;541</td>
    </tr>
    <tr style={{
                "height": "20px"
              }}>
      <td className="s7" dir="ltr" rowSpan="4">
        Twilio
      </td>
      <td className="s6 black" dir="ltr">
        Audio local
      </td>
      <td className="s6">3000000</td>
      <td className="s6" dir="ltr">
        0,01300
      </td>
      <td className="s6" dir="ltr">
        0,00850
      </td>
      <td className="s6" dir="ltr">
        0
      </td>
      <td className="s6">64&nbsp;500</td>
    </tr>
    <tr style={{
                "height": "20px"
              }}>
      <td className="s6" dir="ltr">
        Audio web
      </td>
      <td className="s6">3000000</td>
      <td className="s6" dir="ltr">
        0,00400
      </td>
      <td className="s6" dir="ltr">
        0,00400
      </td>
      <td className="s6" dir="ltr">
        0
      </td>
      <td className="s6">24&nbsp;000</td>
    </tr>
    <tr style={{
                "height": "20px"
              }}>
      <td className="s6" dir="ltr">
        Video (P2P)
      </td>
      <td className="s6">3000000</td>
      <td className="s6" dir="ltr">
        0,00150
      </td>
      <td className="s6" dir="ltr">
        0,00150
      </td>
      <td className="s6" dir="ltr">
        0
      </td>
      <td className="s6">9&nbsp;000</td>
    </tr>
    <tr style={{
                "height": "20px"
              }}>
      <td className="s6" dir="ltr">
        Video Groups
      </td>
      <td className="s6">3000000</td>
      <td className="s6" dir="ltr">
        0,00400
      </td>
      <td className="s6" dir="ltr">
        0,00400
      </td>
      <td className="s6" dir="ltr">
        0
      </td>
      <td className="s6">24&nbsp;000</td>
    </tr>
  </tbody>
          </table>
        </div>
      </div>
    </div>} mdxType="HtmlElement" />
    <h2 {...{
      "id": "What-is-the-video-group-and-how-is-it-different-from-a-regular-p2p-connection"
    }}>{`What is the video group and how is it different from a regular p2p connection?`}</h2>
    <p>{`In both cases, it is a video call, but video groups, as the name suggests, allows video transfer between a larger group of users. A group conversation can contain up to 50 users at a time. Several additional functionalities are also available, the costs of which are as follows.`}</p>
    <ul>
      <li parentName="ul">{`VIDEO GROUPS RECORDING ($0.004 per participant per minute)`}</li>
      <li parentName="ul">{`VIDEO GROUPS RECORDING COMPOSITIONS ($0.01 per composed minute)`}</li>
      <li parentName="ul">{`MEDIA STORAGE ($0.05 / GB per month)`}</li>
    </ul>
    <p>{`Therefore, the costs for this connection can increase with the availability of additional functionalities. Also note that the cost applies to each user separately, so if there are 50 connected users at once, the cost per minute will be 50 `}{`*`}{` $ 0.004. The same rule applies to Agora, but Qickblox does not charge any additional fees per minute in any of its packages.`}</p>
    <p>{`The pay as you go system may seem very attractive at first, but you need to be careful. In the case of agora or Twilio, the bill can grow dangerously fast with more and more users.`}</p>
    <p>{`Quickblox, however, will not impose a larger bill on us right away. In case of exceeding the limits of the selected package, e.g. exceeding 10,000 connected users at one time with the Startup package, the company will inform you about this kind of event and give you five days to change the package to a higher one. In the event of no intervention by the client, the company blocks the account. This makes Quckblox`}{`’`}{`s policy appear to be the cleanest and user-friendly. However, it is your requirements that will decide which provider is best for you.`}</p>
    <BannerMobileApp title="" description={"Do you want to create a video-chat application?"} mdxType="BannerMobileApp" />
    <h2 {...{
      "id": "What-are-the-technological-limitations-for-WebRTC-for-mobile-applications"
    }}>{`What are the technological limitations for WebRTC for mobile applications?`}</h2>
    <p>{`WebRTC is still mainly supported by desktop browsers. That is why if we want to use this technology on a mobile platform, we must create a dedicated application. The standard for mobile SDK providers is native support, i.e. for Android and IOS platforms. However, this causes the problem that we write the same application twice and as it grows, it becomes a challenging task to keep both versions looking and working identically.`}</p>
    <p>{`That is why we recommend using cross-platform technologies, especially flutter, which is much cheaper to maintain and has almost identical performance to native applications.`}</p>
    <HtmlElement element={<div className='custom-h3'><h3>Agora</h3><img src='/img/webRtcSdks/agoralogo.png' style={{
        transform: 'translateY(10px)'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`It is known that WebRTC works very well on most of desktop browsers and it is much more difficult to implement this technology outside of them. However, Agora offers high-quality SDK, which allows you to create web, desktop and mobile applications using WebRTC.`}</p>
    <p>{`SDK available:`}</p>
    <ul>
      <li parentName="ul">{`macOS`}</li>
      <li parentName="ul">{`Linux`}</li>
      <li parentName="ul">{`Windows`}</li>
      <li parentName="ul">{`Android`}</li>
      <li parentName="ul">{`IOS`}</li>
      <li parentName="ul">{`Flutter`}</li>
      <li parentName="ul">{`React Native`}</li>
      <li parentName="ul">{`Unity`}</li>
      <li parentName="ul">{`Electron`}</li>
    </ul>
    <HtmlElement element={<div className='custom-h3'><h3>Twilio</h3><img src='/img/webRtcSdks/twiliologo.png' style={{
        transform: 'translateY(15px) translateX(-15px)',
        height: '50px'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`Unfortunately, they do not support cross-platform technology, so if you choose this WebRTC provider, you must take care of a separate codebase.`}</p>
    <p>{`SDK available:`}</p>
    <ul>
      <li parentName="ul">{`IOS`}</li>
      <li parentName="ul">{`Android`}</li>
      <li parentName="ul">{`JavaScript`}</li>
    </ul>
    <HtmlElement element={<div className='custom-h3'><h3>Quickblox</h3><img src='/img/webRtcSdks/quicklogo.png' style={{
        transform: 'translateY(7px)',
        height: '33px'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`This company, like Agora, offers a wide range of technologies. On their website we can find documentation for:`}</p>
    <p>{`SDK available:`}</p>
    <ul>
      <li parentName="ul">{`IOS`}</li>
      <li parentName="ul">{`Android`}</li>
      <li parentName="ul">{`JavaScript`}</li>
      <li parentName="ul">{`Flutter`}</li>
      <li parentName="ul">{`React Native`}</li>
    </ul>
    <h3 {...{
      "id": "Summary-of-WebRTC-technological-limitations"
    }}>{`Summary of WebRTC technological limitations`}</h3>
    <p>{`As you can see Agora and Quickblox focus on modern technologies. This makes it highly recommended to choose these providers even if we only target Android or IOS as a single platform.`}</p>
    <p>{`We ourselves recommend Agora SDK, which we used for many reasons in our video chat application for the deaf with Flutter called `}<a parentName="p" {...{
        "href": "http://www.captio.cc/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Captio`}</a>{`.`}</p>
    <Quote content={<p className="content">If you are interested in the Agora implementation in Flutter, see our other <a target="_blank" href="/blog/webrtc/Agora-integration">Agora implementation article</a> or contact us at <a target="_blank" href="/">Allbright.io</a></p>} mdxType="Quote" />
    <h3 {...{
      "id": "Security"
    }}>{`Security`}</h3>
    <p>{`When building an application that operates on user data and files, it is necessary to comply with the privacy policy and terms of service (TOS). When creating global applications and more, you need to take into account such things as GDPR and properly inform your users how their data will be stored. That is why it is very important to consider how the company that provides the WebRTC service to your product takes into account data security and whether they offer any assistance in legally securing your application.`}</p>
    <HtmlElement element={<div className='custom-h3'><h3>Agora</h3><img src='/img/webRtcSdks/agoralogo.png' style={{
        transform: 'translateY(10px)'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`The SDK offered by this company has been designed to comply with global security requirements so that customers do not have to worry about legal problems or data leaks. Special developer guides have been created to help in the implementation of security systems that stand on the side of the application owner.`}</p>
    <p>{`Any data passing through Agora is encrypted by AES-128 / AES-256 and other provided by company algorithms. Moreover, Agora is constantly developing its security systems.`}</p>
    <p>{`For some time now, the company has been shifting from communication via API key to connection encryption by certificates and token verification. The less secure solution will be disabled in the coming months as developers finish implementing new solutions to existing applications.`}</p>
    <HtmlElement element={<div className='custom-h3'><h3>Twilio</h3><img src='/img/webRtcSdks/twiliologo.png' style={{
        transform: 'translateY(15px) translateX(-15px)',
        height: '50px'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`The company is very transparent when it comes to security. They have prepared a full report on practices, certificates and even safeguards against breaching security by their private employees, which you can read at this `}<a parentName="p" {...{
        "href": "https://www.twilio.com/legal/security-overview",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`link`}</a>{`.`}</p>
    <p>{`Twilio has obtained the following security-related certifications: ISO/IEC 27001:2013, System and Organization Control (`}{`”`}{`SOC`}{`”`}{`) 2 - Type II and Payment Card Industry Data Security Standard (`}{`”`}{`PCI DSS`}{`”`}{`). All data stored by Twilio has multiple backups that use Advanced Encryption (AES-256).`}</p>
    <p>{`Besides, the company offers special additional security for specific solutions, such as HIPAA compliance in healthcare. The company makes sure that each of its customers and its partners meets at least the minimum level of security requirements, which in the case of Twilio is very high.`}</p>
    <HtmlElement element={<div className='custom-h3'><h3>Quickblox</h3><img src='/img/webRtcSdks/quicklogo.png' style={{
        transform: 'translateY(7px)',
        height: '33px'
      }} /></div>} mdxType="HtmlElement" />
    <p>{`The encryption of customer personal and business data is the foundation of the Quickblox security structure. All data that is stored and transferred from the client to the server and vice versa is secured by the SSL Secure Socket Layer.`}</p>
    <p>{`Additionally, just like Twilio, the company offers ready-made solutions that take into account such certificates as HIPAA and GDRP. All security is configured when setting up the application`}{`’`}{`s connection to the server.`}</p>
    <HtmlElement element={<div className='custom-h3'><h3>Summary of Security</h3></div>} mdxType="HtmlElement" />
    <p>{`For each of these companies, the security of your data is very important. For each of these WebRTC providers, you can rest assured that their security will meet international standards. What`}{`’`}{`s more, companies offer ready-made solutions for your products and detailed guides on how to secure data on the application side.`}</p>
    <h2 {...{
      "id": "Why-not-own-RTC-implementation"
    }}>{`Why not own RTC implementation?`}</h2>
    <p>{`WebRTC is an open-source technology that has developed very good solutions over the years. The vendors of this technology make it seem fairly easy to use. Unfortunately, when we use suppliers such as Agora, Twilio or Quickblox, we have to take into account high costs, which increase as we develop our product and more and more users use our app.`}</p>
    <p>{`In this situation, the question arises as to whether these costs could not be avoided by creating our WebRTC implementation.`}</p>
    <p>{`However, the high prices of WebRTC SDK providers are not for nothing. They are taken for the following reasons. WebRTC is a great technology used by many companies around the world, but certainly, it is not easy to implement.`}</p>
    <p>{`Understanding it on the theoretical level requires getting familiar with a lot of concepts and understanding the problems that this technology solves.`}</p>
    <Quote content={<p className="content">If you are interested in understanding how complex this technology is, check out <a target="_blank" href="/blog/webrtc-part-2-how-does-it-work">our article where we tell you how WebRTC works</a></p>} mdxType="Quote" />
    <p>{`Another problem is infrastructure and its maintenance costs. The basis of this infrastructure is the STUN server, which is used to establish P2P, i.e. a direct connection between two devices. This STUN server is required every time a connection is established. However, sometimes this connection cannot be achieved due to system or network restrictions. According to statistics, such a situation takes place in 1 out of 10 cases, which gives 10% of our clients. In this case, the connection is no longer P2P and the data is sent via the TURN server. Of course, if we have one. If not, we cut off 10% of customers from our product. However, both of these servers are very expensive and in the case of our implementation, we have to bear the costs ourselves.`}</p>
    <p>{`Without going into further details, this can be summarized in the following conclusion. It does not pay off to build your WebRTC implementation unless you intend to continue sharing it with other people. In such a situation, however, you become WebRTC vendors yourself.`}</p>
    <BannerMobileApp title="" description={"Do you want to build a video-chat Flutter app?"} mdxType="BannerMobileApp" />
    <h2 {...{
      "id": "Summary-of-the-article"
    }}>{`Summary of the article`}</h2>
    <p>{`Together we went through the most important aspects that characterize and distinguish popular WebRTC providers. So let`}{`’`}{`s sum it all up.`}</p>
    <p>{`Each of the companies specializes in certain technological aspects. Agora focuses on the functionality of audio/video communication, offering the widest range of services, including streaming. Twilio does not reduce its services to just one functionality and offers audio, video and all chat quality at the same time. Looking at their offer, however, it is to conclude that they put a bit more work into their SDK responsible for live messages, SMS and chats. Quickblox, on the other hand, offers a balanced service, whereby by choosing one package we get access to all functionalities. There is no straightforward winner in this category as each provider covers a different type of service and you need to choose the one that best suits your business requirements.`}</p>
    <p>{`In terms of technology, however, it is much easier to come to a conclusion about which option is the best. Here, consider Quickbox and Agora. From our experience, we can recommend the second one, which we used in our application called `}<a parentName="p" {...{
        "href": "https://captio.cc/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Captio`}</a>{`. We can recommend Agora in this category with their Flutter SDK.`}</p>
    <p>{`As the next point on the list, let`}{`’`}{`s discuss the subject of the price. It seems to be very user-friendly, but at the same time here raise the most doubts. In this article, we`}{`’`}{`ve met two business models. Fixed monthly cost plans and pay as you go systems.`}</p>
    <p>{`Many aspects determine which supplier is the most financially advantageous. However, it seems that in most situations, Twilio is the least advantageous due to the lack of a free offer that allows you to test the product in a real environment. So if the cost is decisive, then your choice should be Quickblox or Agora.`}</p>
    <p>{`Finally, let`}{`’`}{`s talk about security. All companies present the highest safety standard and there is no doubt about it. There is no need to worry that any of these companies will fail to meet international security requirements. However, each of them offers some special solutions tailored to certain requirements. Probably for the healthcare industry, it will be best to choose Twilio or Quickblox due to the HIPAA certificate, but if we are interested in the game industry, then Agora probably offers the best solutions. It all depends on your business requirements and whether you are interested in any ready to use solution.`}</p>
    <p>{`And here`}{`’`}{`s what we think:`}</p>
    <p>{`Here are the awards we`}{`’`}{`ve given to the three solutions, based on what they are best for:`}</p>
    <ul>
      <li parentName="ul">{`The `}{`”`}{`Best overall WebRTC provider`}{`”`}{` is given to Agora`}</li>
      <li parentName="ul">{`The `}{`”`}{`Best for clear and affordable price`}{`”`}{` is given to Quickblox`}</li>
      <li parentName="ul">{`The `}{`”`}{`Best if you look for chat functionality`}{`”`}{` is given to Twilio`}</li>
      <li parentName="ul">{`The `}{`”`}{`Best for audio/video functionality`}{`”`}{` is given to Agora`}</li>
      <li parentName="ul">{`The `}{`”`}{`Best for technological possibilities and developer support`}{`”`}{` is given to Agora`}</li>
      <li parentName="ul">{`The `}{`”`}{`Best for the balanced option`}{`”`}{` is given to Quickblox`}</li>
    </ul>
    <p>{`And that`}{`’`}{`s it! I hope by now you have a better idea of which solution is best for you, or at least you are slightly better informed as to what is out there. If you need specialists in the field of building mobile applications or the implementation of WebRTC in a mobile app, please contact us at `}<a parentName="p" {...{
        "href": "mailto:contact@allbright.io",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`contact@allbright.io`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      